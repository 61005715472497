<template>
  <div class="card full-height bg-ligth">
    <b-modal ref="consentimento_informado" centered id="transferencia">
      <template #modal-title>
        Generar Consentimiento Informado
      </template>
      <div class="form">
        <div class="form-group row">
          <div class="col-md-12">
            <button :disabled="verif_download" class="btn btn-light-info" type="button" @click="gerarTermo()">
              <i v-if="verif_download == false" class="fas fa-print"></i>
              <b-spinner v-if="verif_download == true" small variant="dark" type="grow" label="Spinning"></b-spinner>
              Generar
              concentimiento</button>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-12">
            <b-form-checkbox id="checkbox" name="checkbox-1" v-model="assinou_termo">
              El paciente ya firmo el consentimento informado
            </b-form-checkbox>
          </label>
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100 text-center">
          <button @click.prevent="confirm('Habilitará el paciente para consulta y salidas de medicaciones')"
            class="btn btn-primary" :disabled="verif">
            Guardar
            <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
          </button>
        </div>
      </template>
    </b-modal>
    <div class="card-header">
      <h3 class="mb-0">Pacientes</h3>
    </div>
    <div class="card-body">
      <div class="row d-flex align-items-end">
        <div class="form-group col-md-3 col-4">
          <label>Tipo</label>
          <select v-model="pesquisa.tipo" class="form-control">
            <option value="rg">rg</option>
            <option value="nome">Nome</option>
          </select>
        </div>
        <div class="form-group col-md-5 col-4">
          <label for="text">Campo</label>
          <input v-model="pesquisa.texto" type="text" class="form-control" id="text" placeholder
            @keyup.enter="pesquisarPaciente" />
        </div>
        <div class="form-group col-md-3 col-4">
          <label>Filial</label>
          <treeselect :options="lista_filials" placeholder="Seleccione una o más(s)..." v-model="pesquisa.filial_id" />
        </div>
        <div class="form-group">
          <button class="btn btn-success btn-sm" @click="pesquisarPaciente()">
            <i class="fa fa-search search-icon m-0 p-0"></i>
          </button>
          <button @click="limparFiltros()" class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover
            title="Limpiar filtros">
            <b-icon icon="x-lg" scale="1" variant="danger"></b-icon>
          </button>
        </div>
      </div>
      <b-table class="
          table
          table-head-custom
          table-vertical-center
          table-head-bg
          table-borderless
        " show-empty empty-text="Ningún registro encontrado!" :fields="[
          'id',
          'nome_sobrenome',
          'cpf',
          'RG|CI',
          'status',
          'email',
          'filial_origem',
          'actions'
        ]" :items="lista_usuarios" :per-page="perPage" :current-page="currentPage" id="funcionarios-table">
        <template #cell(status)="{ item }">
          <div class="text-right w-100 text-center">
            <span class="label label-lg label-inline" :class="{
              'label-light-primary': item.status === 1,
              'label-light-warning': item.status === 2,
            }">
              {{ item.status === 1 ? "Activo" : "Desactivado" }}
            </span>
          </div>
        </template>
        <template #cell(RG|CI)="{ item }">
          {{ item.rg }}
        </template>
        <template #cell(actions)="{ item }">
          <b-dropdown size="sm" variant="link"
            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right
            no-flip>
            <template v-slot:button-content>
              <i class="ki ki-bold-more-ver"></i>
            </template>
            <!--begin::Navigation-->
            <div class="navi navi-hover min-w-md-230px">
              <b-dropdown-text tag="div" class="navi-item">
                <a class="dropdown-item" @click="atualizar(item)">Editar</a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item">
                <a class="dropdown-item" @click="trocar_pagina(item.id)">Histórico atenciones</a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item">
                <a class="dropdown-item" type="button"
                  @click="assinar_consentimento(item.id, item.assinou_termo)">Consentimiento
                  Informado</a>
              </b-dropdown-text>

              <b-dropdown-text tag="div" class="navi-item">
                <a class="dropdown-item" @click="confirm('excluir', item)">Exluir</a>
              </b-dropdown-text>
            </div>

            <!--end::Navigation-->
          </b-dropdown>
        </template>

      </b-table>
      <b-pagination v-model="currentPage" :total-rows="lista_usuarios.length" :per-page="perPage"
        aria-controls="funcionarios-table">
      </b-pagination>
    </div>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { getFilialId } from "../../../core/services/filial.service";
import { FALSE } from "sass";

export default {
  mixins: [fireAlert],
  data() {
    return {
      id_usuario_atual: null,
      assinou_termo: null,
      verif: false,
      verif_download: false,
      perPage: 10,
      selectedUser: null,
      disableSaveModal: false,
      roleForm: {
        user_id: null,
        roles: [],
      },
      perfil_id: 3,
      pesquisa: {
        tipo: "nome",
        texto: null,
        perfil_id: 3,
        filial_id: getFilialId()
      },
      currentPage: 1,

      value: "",
      filiais: [
        {
          id: "PJC",
          label: "PJC",
          children: [],
        },
        {
          id: "CDE",
          label: "CDE",
          children: [],
        },
      ],
    };

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Paciente" }]);
  },
  created() {
    this.listar_usuarios_matriculas();
    // this.listar_perfil();
    this.listar_filial();
    console.log("Pesquisa:", this.pesquisa)
    console.log("options", this.options)
  },
  watch: {
    perfil_id() {
      console.log(this.perfil_id);
      this.buscarUsers();
    },
    pesquisa() {
      console.log(this.pesquisa)
    }

  },
  computed: {
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_perfil() {
      return this.$store.state.configEmpresa.lista_perfil;
    },
    lista_filials() {
      return this.$store.state.configEmpresa.lista_filials;
    },
    lista_roles() {
      return this.$store.state.permissoes.lista_roles.map((role) => ({
        id: role.id,
        label: role.name,
      }));
    },
    lista_usuarios() {
      return this.$store.state.configUsuarios.lista_usuarios_matriculas
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
  },
  methods: {
    async confirm(tipo, item) {
      let msg = `Vas a ` + tipo + ` un usuario definitivamente del sistema`
      if (tipo == 'Habilitará el paciente para consulta y salidas de medicaciones') msg = tipo;
      console.log(item);
      await this.$confirm({
        title: "Estas seguro?",
        message: msg,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "excluir") this.excluir(item.id);
            if (tipo == 'Habilitará el paciente para consulta y salidas de medicaciones') this.update_entrega();
          }
        },
      });
    },
    async submitPermissions(e) {
      e.preventDefault();
      this.disableSaveModal = true;
      this.$store
        .dispatch("permissoes/vincular_regra_usuario", this.roleForm)
        .then(() => {
          this.roleForm.roles = [];
          this.$bvModal.hide("modal-roles");
          this.fireAlert({ title: "Actualizado con éxito" });
        })
        .catch(() => this.fireAlert(this.mensagem_alert))
        .finally(() => (this.disableSaveModal = false));
    },
    async showModalPermission(item) {
      console.log(item);
      this.roleForm.user_id = item.user_id;
      this.$bvModal.show("modal-roles");
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("permissoes/read_user_roles", item.user_id);
      this.roleForm.roles = this.$store.state.permissoes.user_roles.reduce(
        (prev, curr) => [...prev, curr.id],
        []
      );
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async buscarUsers() {
      //  this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch(
        "configUsuarios/listar_usuarios_perfil",
        this.perfil_id
      );
      //  this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async pesquisarPaciente() {
      console.log(this.pesquisa.tipo)
      var p = { texto: this.pesquisa.texto, status: 3, tipo_pesquisa: this.pesquisa.tipo, filial_id: this.pesquisa.filial_id };
      await this.$store.dispatch("configUsuarios/pesquisar_usuarios", p);
    },
    async excluir(id) {
      await this.$store.dispatch("configUsuarios/block_usuario", id);
      await this.$store.dispatch("configUsuarios/listar_usuarios_perfil", 3);
      this.fireAlert(this.mensagem_alert);
    },
    async listar_usuarios_matriculas() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("configUsuarios/listar_usuarios_perfil", this.pesquisa);
      // await this.$store.dispatch("permissoes/all_roles");
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
      this.$store.dispatch("configEmpresa/atualizar", "");
    },
    atualizar(value) {
      this.$router.push({ name: "createPaciente" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async pesquisar() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch(
        "configUsuarios/pesquisar_usuarios",
        this.pesquisa
      );
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async trocar_pagina(id) {
      this.$router.push({
        name: "atendimentosPaciente",
        params: { usuario_id: id },
      });
    },
    assinar_consentimento(id, check_termo) {
      this.id_usuario_atual = id
      if (check_termo == 1)
        this.assinou_termo = true
      else this.assinou_termo = false
      console.log(check_termo)
      this.$refs["consentimento_informado"].show();
    },
    async gerarTermo() {
      this.verif_download = true
      // this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch(
        "configUsuarios/gerar_termo_consentimento",
        this.id_usuario_atual
      );
      this.verif_download = false
      // this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async update_entrega() {
      this.verif = true;
      const dados = {
        id: this.id_usuario_atual,
        assinou_termo: this.assinou_termo
      }
      await this.$store.dispatch("configUsuarios/update_entrega", dados);
      var p = { texto: this.pesquisa.texto, status: 3, tipo_pesquisa: this.pesquisa.tipo, filial_id: this.pesquisa.filial_id };
      await this.$store.dispatch("configUsuarios/pesquisar_usuarios", p);
      this.verif = false;
      this.$refs["consentimento_informado"].hide();
      this.fireAlert({
        ...this.mensagem_alert,
      });
    },
    async listar_filial() {
      await this.$store.dispatch("configEmpresa/listar_filial");
      const iten = {
        id:0,
        label:"Todas las filiales"
      }
      this.lista_filials.unshift(iten)
      // var pjc = [];
      // var cde = [];
      // var p = {};
      // for (let i = 0; i < this.lista_filials.length; i++) {
      //   const filial = this.lista_filials[i];
      //   p = {
      //     id: filial.id,
      //     label: filial.nome,
      //   };

      //   if (filial.empresa_id == 1) {
      //     pjc[i] = p;
      //   } else {
      //     cde[i] = p;
      //   }
      // }
      // this.filiais[0].children = pjc;
      // this.filiais[1].children = cde;
      // console.log(this.filiais);
    },

    limparFiltros() {
      this.pesquisa.tipo = "nome"
      this.pesquisa.texto = ""
      this.pesquisa.perfil_id = 3
      this.pesquisa.filial_id = getFilialId()
      this.listar_usuarios_matriculas()
    },
  },
};
</script>
<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>